<template>
  <div class="storeset">
    <div class="storeset-left" v-if="false">
      <div class="storeset-left-title">连锁门店结构</div>
      <div class="storeset-left-cont">
        <el-menu
          default-active="2"
          class="el-menu-vertical-demo"
          @open="handleOpen"
          @close="handleClose"
        >
          <el-submenu index="1">
            <template slot="title">
              <i class="el-icon-location"></i>
              <span>001小花豆总部</span>
            </template>
            <el-menu-item index="1-1">002小花豆白云开发部</el-menu-item>
          </el-submenu>
          <el-submenu index="2">
            <template slot="title">
              <i class="el-icon-location"></i>
              <span>003小花豆研发总部</span>
            </template>
            <el-menu-item index="1-1">004小花豆花都研发部</el-menu-item>
          </el-submenu>
        </el-menu>
      </div>
      <div class="storeset-left-btn">
        <el-button type="primary" @click="edit(1)">
          <i class="el-icon-circle-plus-outline el-icon--right"></i>
          <span>添加门店</span>
        </el-button>
      </div>
    </div>
    <div class="storeset-right" style="width:100%">
      <div class="storeset-right-img"></div>
      <div class="storeset-right-cont">
        <div class="storeset-right-cont-logo">
          <img :src="formData.photo" alt="" />
        </div>
        <div class="storeset-right-cont-box">
          <div class="storeset-right-cont-list" style="margin-top:71px;">
            <div class="storeset-right-cont-list-text">
              <span style="font-size:18px;font-weight: Bold;color: #000000;"
                >基本信息</span
              >
            </div>
          </div>
          <div class="storeset-right-cont-list">
            <div class="storeset-right-cont-list-text">
              <span>店铺全称：</span>
              <span>{{ formData.name }}</span>
            </div>
          </div>
          <div class="storeset-right-cont-list" v-if="false">
            <div class="storeset-right-cont-list-text">
              <span>上级全称：</span>
              <span>小花豆白云技术部</span>
            </div>
          </div>
          <div class="storeset-right-cont-list">
            <div class="storeset-right-cont-list-text">
              <span>联系人：</span>
              <span>{{ formData.manager }}</span>
            </div>
          </div>
          <div class="storeset-right-cont-list">
            <div class="storeset-right-cont-list-text">
              <span>联系电话：</span>
              <span>{{ formData.phone }}</span>
            </div>
          </div>
          <div class="storeset-right-cont-list">
            <div class="storeset-right-cont-list-text">
              <span>店铺地址：</span>
              <span>
                {{ formData.province }}{{ formData.city }}{{ formData.area
                }}{{ formData.address }}
              </span>
            </div>
          </div>
          <div class="storeset-right-cont-list">
            <div class="storeset-right-cont-list-text">
              <span>营业时间：</span>
              <span>{{ formData.yingye_time }}</span>
              <!-- <span v-show="false" style="margin:0 10px;">-</span>
              <span v-show="false">{{ formData.name }}</span> -->
            </div>
          </div>
          <div class="storeset-right-cont-btn">
            <el-button size="small" icon="el-icon-edit-outline" @click="edit(0)"
              >编辑</el-button
            >
          </div>
        </div>
      </div>
    </div>

    <el-dialog
      :title="type ? '添加店铺' : '修改店铺'"
      :visible.sync="dialogVisible"
      width="35%"
      :before-close="handleClose"
      :show-close="false"
    >
      <div class="storeset-edit">
        <el-form label-position="left" label-width="100px" :model="formData">
          <el-form-item label="门店LOGO">
            <div class="storeset-edit-list">
              <img
                :src="formData.photo"
                alt=""
                style="width:148px;height:148px;margin-right:10px;border-radius:10px;"
              />
              <el-upload
                action="/api/app/meiye/upload/shop_photo"
                class="avatar-uploader"
                :show-file-list="false"
                :http-request="update"
              >
                <i class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </div>
          </el-form-item>
          <el-form-item label="店铺名称">
            <div class="storeset-edit-list">
              <el-input v-model="formData.name"></el-input>
            </div>
          </el-form-item>
          <el-form-item label="店铺简称">
            <div class="storeset-edit-list">
              <el-input v-model="formData.name_as"></el-input>
            </div>
          </el-form-item>
          <el-form-item label="总店" v-if="false">
            <div class="storeset-edit-list">
              <el-switch
                v-model="formData.type"
                active-text="总店"
                inactive-text="分店"
              >
              </el-switch>
            </div>
          </el-form-item>
          <el-form-item label="上级" data-num="!formData.type" v-if="false">
            <div class="storeset-edit-list">
              <el-input v-model="formData.name"></el-input>
            </div>
          </el-form-item>
          <el-form-item label="联系人">
            <div class="storeset-edit-list">
              <el-input v-model="formData.manager"></el-input>
            </div>
          </el-form-item>
          <el-form-item label="联系电话">
            <div class="storeset-edit-list">
              <el-input v-model="formData.phone"></el-input>
            </div>
          </el-form-item>
          <el-form-item label="选择地区">
            <div class="storeset-edit-list">
              <el-cascader
                style="width:100%"
                size="large"
                :options="options"
                v-model="selectedOptions"
                @change="handleChange"
              >
              </el-cascader>
            </div>
          </el-form-item>
          <el-form-item label="详细地址">
            <div class="storeset-edit-list">
              <!-- <el-input v-model="formData.addr"></el-input> -->
              <!-- <el-amap-search-box
                class="search-box"
                :search-option="searchOption"
                :on-search-result="onSearchResult"
              ></el-amap-search-box>
              <el-amap vid="amapDemo"> </el-amap> -->
              <!-- 地图 -->
              <div id="container" v-show="false"></div>
              <input
                type="text"
                id="place"
                class="el-input__inner"
                v-model="formData.address"
              />
              <!-- 地图 -->
            </div>
          </el-form-item>
          <el-form-item label="营业时间">
            <div class="storeset-edit-list">
              <el-input v-model="formData.yingye_time"></el-input>
            </div>
          </el-form-item>
          <!-- <el-form-item label="状态">
            <div class="storeset-edit-list">
              <el-switch
                v-model="formData.status"
                active-text="开启"
                inactive-text="冻结"
              >
              </el-switch>
            </div>
          </el-form-item> -->
          <el-form-item label="门店描述信息">
            <div class="storeset-edit-list">
              <el-input
                type="textarea"
                placeholder="请输入内容"
                v-model="formData.detail"
                maxlength="300"
                show-word-limit
              >
              </el-input>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button v-if="type" type="primary" @click="dialogVisible = false"
          >添加店铺</el-button
        >
        <el-button v-if="!type" type="primary" @click="updateList"
          >保存修改</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { regionData, CodeToText } from 'element-china-area-data';
export default {
  data() {
    return {
      dialogVisible: false,
      type: "", //保存和修改标识 1添加0编辑更新
      formData: {},
      dialogImageUrl: "",
      zoom: "",
      center: "",
      token: sessionStorage.getItem("token"),
      file: "",
      options: regionData,
      selectedOptions: [],
    };
  },
  methods: {
    /**
     * 选择地区
     */
    handleChange (value) {
      console.log(value);
      console.log('options', this.options);
      console.log(CodeToText[this.selectedOptions[0]]);
      console.log(CodeToText[this.selectedOptions[1]]);
      console.log(CodeToText[this.selectedOptions[2]]);
      this.formData.province = CodeToText[this.selectedOptions[0]];
      this.formData.city = CodeToText[this.selectedOptions[1]];
      this.formData.area = CodeToText[this.selectedOptions[2]];
    },
    searchOption(val) {
      console.log(val);
    },
    onSearchResult(val) {
      console.log(val);
      let value = document.querySelectorAll(".search-box-wrapper input")[0];

      if (val.length > 1) {
        value.value = val[1].name + val[1].address;
      } else {
        value.value = val[0].name + val[0].address;
      }
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    /* 添加更新 */
    edit(obj) {
      console.log(obj);
      this.type = obj;
      let time = null;
      this.dialogVisible = !this.dialogVisible;
      clearInterval(time);

      time = setInterval(() => {
        let d = document.querySelectorAll("div");
        if (!this.dialogVisible) {
          clearInterval(time);
        }
        d[d.length - 1].parentNode.parentNode.style.zIndex = 4000;
      }, 1000);

      // setTimeout(() => {
      //   this.getMap();
      // }, 10);
    },
    update(file) {
      const isJPG = ["image/jpeg", "image/png", "image/gif", "image/webp"];
      const isLt2M = file.file.size / 1024 / 1024 < 2;
      if (!isJPG.includes(file.file.type)) {
        this.$message.error("上传图片只能是 JPG、PNG、GIF 、WEBP格式!");
        return false;
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 2MB!");
        return false;
      }
      console.log(file);
      let fd = new FormData(); // FormData 对象
      fd.append("image", file.file); // 文件对象
      fd.append("token", this.token);
      this.$axios({
        href: "/api/app/meiye/upload/shop_photo",
        data: fd,
      }).then((res) => {
        console.log(res);
        this.formData.photo = res.data.data.url;
      });
    },
    /* 获取门店列表 */
    getList() {
      this.$axios({
        href: "/api/app/meiye/shop/store",
      }).then((res) => {
        console.log('getListRes', res);
        const that = this;
        res.data[0].status = res.data[0].status ? true : false;
        that.formData = res.data[0];
        const province = res.data[0].province ? res.data[0].province : '';
        const city = res.data[0].city ? res.data[0].city : '';
        const area = res.data[0].area ? res.data[0].area : '';
        if (province) {
          const optionsLen = that.options.length;
          for (let i = 0; i < optionsLen; i++) {
            if (that.options[i].label.indexOf(province) != -1) {
              that.selectedOptions[0] = that.options[i].value;
              if (city) {
                const childrenLen = that.options[i].children.length;
                for (let j = 0; j < childrenLen; j++) {
                  if (that.options[i].children[j].label.indexOf(city) != -1) {
                    that.selectedOptions[1] = that.options[i].children[j].value;
                    if (area) {
                      const grandsonLen = that.options[i].children[j].children.length;
                      for (let k = 0; k < grandsonLen; k++) {
                        if (that.options[i].children[j].children[k].label.indexOf(area) != -1) {
                          that.selectedOptions[2] = that.options[i].children[j].children[k].value;
                          break;
                        }
                      }
                    }
                    break;
                  }
                }
              }
              break;
            }
          }
        }
      });
    },
    updateList() {
      // this.formData.status = this.formData.status ? 1 : 0;
      this.$axios({
        href: "/api/app/meiye/shop/edit",
        data: this.formData,
      }).then(() => {
        this.dialogVisible = false;
        this.getInit();
        this.getList();
        
        this.$message({
          message: "修改成功~",
          type: "success",
        });
      });
    },
    /**
     * 根据输入的地址提供地址补全选项（暂时用不上）
     */
    getMap() {
      let _this = this;
      var map = new window.qq.maps.Map(document.getElementById("container"), {
        center: new window.qq.maps.LatLng(39.916527, 116.397128),
        zoom: 13,
      });
      //实例化自动完成
      var ap = new window.qq.maps.place.Autocomplete(
        document.getElementById("place")
      );
      var keyword = "";
      //调用Poi检索类。用于进行本地检索、周边检索等服务。
      var searchService = new window.qq.maps.SearchService({
        complete: function(results) {
          if (results.type === "CITY_LIST") {
            searchService.setLocation(results.detail.cities[0].cityName);
            searchService.search(keyword);
            return;
          }

          var pois = results.detail.pois;
          // console.log(pois)
          let arr = pois[0];
          let province = arr.address.includes("省")
            ? arr.address.split("省")[0] + "省"
            : "";
          let city =
            province && arr.address.split("省")[1].includes("市")
              ? arr.address.split("省")[1].split("市")[0] + "市"
              : "";
          let area =
            city &&
            arr.address
              .split("省")[1]
              .split("市")[1]
              .includes("区")
              ? arr.address
                  .split("省")[1]
                  .split("市")[1]
                  .split("区")[0] + "区"
              : "";
          console.log('province', province, 'city', city,'area', area);

          _this.formData.province = province;
          _this.formData.city = city;
          _this.formData.area = area;
          _this.formData.address = arr.address;

          _this.formData.position_x = arr.latLng.lat;
          _this.formData.position_y = arr.latLng.lng;

          // console.log(_this.formData,pois[0])

          var latlngBounds = new window.qq.maps.LatLngBounds();
          for (var i = 0, l = pois.length; i < l; i++) {
            var poi = pois[i];
            latlngBounds.extend(poi.latLng);
            var marker = new window.qq.maps.Marker({
              map: map,
              position: poi.latLng,
            });

            marker.setTitle(poi.name);
          }
          map.fitBounds(latlngBounds);
        },
      });
      //添加监听事件
      window.qq.maps.event.addListener(ap, "confirm", function(res) {
        keyword = res.value;
        searchService.search(keyword);
      });
    },
    /* 更新本地缓存数据 */
    getInit() {
      this.$axios({
        href: "/api/app/meiye/home/init",
      }).then((res) => {
        sessionStorage.setItem("init", JSON.stringify(res.data));
         setTimeout(()=>{
          location.reload();
        },500)
      });
    },
  },
  created() {
    this.getList();
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.amap-sug-result {
  z-index: 4000;
}
.storeset {
  width: 100%;
  height: 100%;
  display: flex;
  //   background: red;

  &-left {
    width: 20%;
    height: 100%;
    margin-right: 1%;
    box-sizing: border-box;
    padding-top: 10px;
    // background: #fff;
    &-title {
      width: 100%;
      height: 50px;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: 1px solid #ccc;
    }
    &-cont {
      width: 100%;
      height: 85%;
      background: #fff;
      overflow-x: hidden;
      overflow-y: auto;
    }
    &-btn {
      width: 100%;
      height: 50px;
      overflow: hidden;
      button {
        width: 100%;
        height: 100%;
        border-radius: 0;
      }
    }
  }
  &-right {
    width: 79%;
    height: 100%;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    position: relative;
    &-img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      // filter: blur(1px);
      background-image: url("./../../assets/bg.png");
      background-size: cover;
    }
    // background: pink;
    &-cont {
      position: relative;
      width: 78%;
      height: 68%;
      color: #000000;
      font-weight: 800;
      background: #fff;
      border: 1px solid #f2f2f2;
      display: flex;
      overflow: hidden;
      &-logo {
        width: 30%;
        height: 100%;
        // background: red;
        padding-top: 159px;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        img {
          border-radius: 50%;
          width: 144px;
          height: 144px;
        }
      }
      &-box {
        width: 70%;
        height: 100%;
      }
      &-list {
        width: 100%;
        height: 60px;
        margin-top: 10px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        &-text {
          width: 80%;
          height: 100%;
          // background: blue;
          padding-left: 0%;
          box-sizing: border-box;
          text-align: left;
          line-height: 60px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          span:first-child {
            font-size: 16px;
            font-weight: Normal;
            color: #000000;
          }
          span:last-child {
            font-size: 14px;
            font-weight: Normal;
            color: #999999;
          }
        }
      }
      &-btn {
        position: absolute;
        top: 20px;
        right: 10px;
        width: 100px;
        height: 50px;
        //   background: red;
      }
    }
  }

  &-edit {
    width: 100%;
    min-height: 500px;
    &-list {
      width: 100%;
      min-height: 40px;
      //   background: red;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }
}
</style>

<style moudle>
.storeset .el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 100%;
  min-height: 400px;
}

.storeset .el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 100%;
  min-height: 400px;
}
.storeset-right-cont-list {
  transition: all 0.5s;
}

/* @media screen and (max-width: 1500px) {
  .storeset-right-cont-list {
    margin-left: -10px;
  }
}
@media screen and (max-width: 1200px) {
  .storeset-right-cont-list {
    margin-left: -20px;
  }
} */

.el-vue-search-box-container .search-box-wrapper .search-btn {
  display: none !important;
}
.el-vue-search-box-container {
  width: 100% !important;
}
.el-vue-search-box-container .search-tips {
  width: 100%;
}
.storeset .avatar-uploader {
  font-size: 20px;
  cursor: pointer;
  border: 1px dashed #d9d9d9;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 148px;
  height: 148px;
  margin-right: 10px;
  border-radius: 10px;
}
.storeset .avatar-uploader:hover {
  border: 1px dashed #409eff;
}
.el-upload {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
